const stateListFormatter = (data, dataKey) => {
    const dataSet = {};

    // Empty values not working with mui Select, so commenting the following code
    // dataSet[dataKey] = new Array({
    //     "value": "",
    //     "name": "All States"
    // })
    // dataSet[dataKey].push(...JSON.parse(JSON.stringify(data.items)));

    dataSet[dataKey] = JSON.parse(JSON.stringify(data.items));

    dataSet[dataKey].forEach((obj) => {
        obj["value"] = obj.id
    })

    return dataSet;
};

export default stateListFormatter;
