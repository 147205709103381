import { PMGrid, PMText } from "../../pmcomponents";
import {BaseWidget, BaseWidgetProps } from "../BaseWidget";
import Icon from "@mui/material/Icon";

export interface MetricWidgetProps {
    metricValue?: string,
    metricValueColor?: string,
    metricPrefix?: string;
    metricIcon?: string;
    metricIconColor?: any;
}

export const MetricWidget = (props: BaseWidgetProps & MetricWidgetProps) => {
    const { metricValue, metricPrefix, metricIcon, metricIconColor, metricValueColor, onClick, ...baseProps } = props;

    let formattedMetricValue = props.metricValue;

    if (metricPrefix) {
        formattedMetricValue = `${props.metricPrefix}${formattedMetricValue}`;
    }

    return (
        <BaseWidget {...baseProps}>
            <PMGrid flexDirection="row" container justifyContent="center" alignItems="center">
                {
                    metricIcon &&
                    <Icon color={metricIconColor} sx={{margin:1}}>{metricIcon}</Icon>
                }
                <PMText variant="h2" color={metricValueColor}>{formattedMetricValue}</PMText>
            </PMGrid>
        </BaseWidget>
    )
}

MetricWidget.defaultProps = {
    metricIconColor: "inherit",
    metricValueColor: "inherit"
}

export default MetricWidget