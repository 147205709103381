import React from "react";
import FormContainer from "./FormContainer";
import FormField from "./FormField";

class Forms extends React.Component {
    constructor(props){
        super(props)
    }
    onSubmitEventHandler = (validatioState, form) => {
        console.log(validatioState, form);
        if (validatioState) {
            if(this?.props?.Onsubmit){
                this?.props?.Onsubmit(form)
            }
            console.log("form is valid and ready to be send to server!");
        }
    };
    render() {
        return (
        <FormContainer onSubmit={this.onSubmitEventHandler}>
            {(values, submitOccurred) => (
            <div>
                {this.props.children?
                    Array.isArray(this.props.children)?
                        this.props.children.map((child)=>{
                            return <FormField {...child.props}
                                    Component={child.type}
                                    validators={child.props.validation}
                                />
                            })
                        :<FormField {...this.props.children.props}
                            Component={this.props.children.type}
                            validators={this.props.validation}
                            />
                    :""
                }
            </div>
            )}
        </FormContainer>
        );
    }
}
export default Forms;
