import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const noteCreateSlice = createApi({
    reducerPath: "noteCreate",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        noteCreate: builder.mutation({
            query: (data) => ({ url: "/interactions/api/v1/note/create", method: "POST", body: data }),
        }),
    }),
});

export const { useNoteCreateMutation } = noteCreateSlice;
