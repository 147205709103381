import { Icon } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid, DataGridProps, GridColDef, GridEventListener, GridRowsProp, GridColTypeDef, GridRenderCellParams, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { format,parseISO  } from 'date-fns';

import "./PMTable.scss";

export interface PMTableProps extends DataGridProps {
    grid?: number;
    Onclick?: Function;
    showSerialNumber?: boolean;
    showExportOptions?: boolean;
    headerColor?: string;
    rows: any
    columns: any[]
}

const locale = 'en-IN'

const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'INR',
});

const currencyType: GridColTypeDef = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
}


const dateType: GridColTypeDef = {
    valueFormatter: ({ value }) => format(parseISO(value), "dd-MMM-yyyy")
}

const iconType: GridColTypeDef = {
    type: 'string',
    renderCell: (params: GridRenderCellParams<string>) => (
        <Icon>{params.value}</Icon>
    )
}
    

function CustomToolbar() {
    return (
        <GridToolbarContainer >
              <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const PMTable = (props: PMTableProps ) => {
    const {showSerialNumber, showExportOptions, headerColor, rows, columns, ...baseProps} = props;
    // const handleClick = (rowData ) => {
    //   console.log(rowData)
    //   // props?.Onclick()
    // }

    let columnDefs = columns.map((colDef) => {
        let typeDef = {}
        switch (colDef.type) {
            case "date":
                typeDef = dateType
                break
            case "currency":
                typeDef = currencyType
                break
            case "icon":
                typeDef = iconType
                break
            }

        return {
            ...colDef,
            ...typeDef,
            flex: colDef.flex ? colDef.flex: 1
        }
    })

    if (showSerialNumber) {
        columnDefs.unshift({
            field: "id",
            headerName: "No.",
            filterable: false,
            flex: 0,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        });
    }

    let datagridAdditionalProps = {}
    if (showExportOptions) {
        datagridAdditionalProps["components"] = {
            Toolbar: CustomToolbar,
        }
    }

    datagridAdditionalProps["sx"] = {
        border: 0,
        "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
            display: "none"
        },
        "& .MuiDataGrid-columnHeaders": {
            bgcolor: headerColor,
            borderRadius: 0
        }
    }

    const handleRowClick: GridEventListener<"rowClick"> = (params) => {
        // console.log(params.row);
        if (props?.Onclick) {
            props?.Onclick(params.row);
        }
    };
    return (
        <div style={{ height: "auto", overflow: "auto", width: "100%" }}>
            <DataGrid onRowClick={handleRowClick} columns={columnDefs} rows={rows} autoHeight={true}
            {...datagridAdditionalProps}
            {...baseProps} />
        </div>
    );
};

PMTable.defaultProps = {
    showSerialNumber: false,
    showExportOptions: false,
    headerColor: "grey.100"
};

export default PMTable;
