import React from "react";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { PMGrid } from "../../pmcomponents";
import { useSelector } from "react-redux";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import {
    FormContainer,
    TextFieldElement,
    SelectElement,
    MultiSelectElement,
    SwitchElement,
    PasswordElement,
    DatePickerElement,
    DateTimePickerElement
} from "react-hook-form-mui";

export interface FormWidgetProps extends BaseWidgetProps {
    text?: string;
    textColor?: string;
    icon?: string;
    defaultValues?: Object;
    fieldsets?: Array<any>;
    fullWidth?: boolean;
    Onsubmit?: Function;
    submitButtonLabel?: string;
}

export const FormWidget = (props: FormWidgetProps) => {
    const {submitButtonLabel, Onsubmit, defaultValues, fullWidth, ...baseProps } = props;

    const onSubmit = (data: Object) => {
        console.log("Submitted Form Data", data);
        if (props.Onsubmit) {
            props.Onsubmit(data);
        }
    };

    return (
        <BaseWidget {...baseProps}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormContainer onSuccess={onSubmit} defaultValues={props.defaultValues}>
                <PMGrid
                    container
                    direction={props.direction || "column"}
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {props.fieldsets.map((fieldset, fsIndex) => {
                        return (
                            <PMGrid
                                key={fsIndex}
                                container
                                direction={fieldset.direction || "row"}
                                spacing={2}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {fieldset.fields.map((field, fIndex) => {
                                    let fieldType: any = TextFieldElement;
                                    let fieldStyleProps = {}

                                    if (props.fullWidth || fieldset.fullWidth || field.fullWidth) {
                                        field["fullWidth"] = true
                                        fieldStyleProps["width"] = "100%"
                                    }

                                    if (!field.inputProps) {field.inputProps = {}}

                                    if (field.prefix) {
                                        field.inputProps["startAdornment"] = <InputAdornment position="start">{field.prefix}</InputAdornment>;
                                    }

                                    if (field.suffix) {
                                        field.inputProps.endAdornment = (
                                            <InputAdornment position="end">{field.suffix}</InputAdornment>
                                        );
                                    }

                                    switch (field.type) {
                                        case "text":
                                        case "number":
                                            fieldType = TextFieldElement;
                                            break;
                                        case "select":
                                            fieldType = SelectElement;
                                            break;
                                        case "multiselect":
                                            fieldType = MultiSelectElement;
                                            break;
                                        case "switch":
                                            fieldType = SwitchElement;
                                            break;
                                        case "password":
                                            fieldType = PasswordElement;
                                            break;
                                        case "date":
                                            fieldType = DatePickerElement;
                                            field.inputFormat = "dd/MMM/yyyy"
                                            break;
                                        case "datetime":
                                            fieldType = DateTimePickerElement;
                                            field.inputFormat = "dd/MMM/yyyy"
                                            break;
                                        case "hidden":
                                            fieldType = TextFieldElement;
                                            fieldStyleProps["display"] = "none"
                                            break;
                                    }

                                    return (
                                        <PMGrid key={fIndex} sx={fieldStyleProps}>
                                            {React.createElement(fieldType, {
                                                ...field
                                            })}
                                        </PMGrid>
                                    );
                                })}
                            </PMGrid>
                        );
                    })}
                    <PMGrid container justifyContent="flex-end">
                        <Button variant="contained" type="submit">
                            {props.submitButtonLabel}
                        </Button>
                    </PMGrid>
                </PMGrid>
            </FormContainer>
            </LocalizationProvider>
        </BaseWidget>
    );
};

FormWidget.defaultProps = {
    direction: "column",
    submitButtonLabel: "Submit",
    fullWidth: false,
};

export default FormWidget;
