// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useCampaignListQuery } from "../store/campaignListSlice";
import { default as campaignListFormatter } from "../transformations/campaignListFormatter";
function CampaignList() {
    const campaignListData = useSelector((states) => states?.appStore?.campaignListData);
    const [campaignListSkip, setcampaignListSkip] = useState(true);
    const [campaignListParams, setcampaignListParams] = useState({});
    const campaignListQuery = useCampaignListQuery(campaignListParams, { skip: campaignListSkip });
    const dispatch = useDispatch();

    const saveCampaignListData = (data) => {
        var formatedData = {
            ...campaignListFormatter(data.data || data, "campaignListData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadCampaignDataSuccess = (data) => {
        saveCampaignListData(data);
    };
    useEffect(() => {
        if (campaignListQuery.isSuccess) {
            loadCampaignDataSuccess(campaignListQuery);
        }
    }, [campaignListQuery]);
    const campaignList = () => {
        setcampaignListSkip(false);
    };

    useEffect(() => {
        campaignList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid>
                    <TableWidget
                        header="All Campaigns"
                        showSerialNumber={true}
                        rows={campaignListData || []}
                        columns={[
                            { headerName: "Name", field: "name", flex: 1 },
                            { headerName: "Start Date", field: "start_date", type: "date", flex: 1 },
                            { headerName: "End Date", field: "end_date", type: "date", flex: 1 },
                            { headerName: "Budgeted Cost", field: "budgeted_cost", flex: 1 },
                            { headerName: "Expected Acquisitions", field: "expected_acquisitions", flex: 1 },
                            { headerName: "Source", field: "medium", flex: 1 },
                            { headerName: "Medium", field: "source", flex: 1 },
                            { headerName: "Active", field: "is_active", type: "boolean", flex: 1 },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default CampaignList;
