const employeeDetailFormatter = (data, dataKey) => {
    const dataSet = {};
    if (data.items) {
        dataSet[dataKey] = data.items[0];
    } else {
        dataSet[dataKey] = data;
    }
    return dataSet;
};

export default employeeDetailFormatter;
