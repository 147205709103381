// @ts-nocheck
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./authComponent/AuthenticatedRoute";
import UnAuthenticatedRoute from "./authComponent/UnAuthenticatedRoute";
import { mergeStore } from "./store/appSlice";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import {
    Login,
    Home,
    MyDashboard,
    MyTasks,
    EmployeeTargetCreate,
    CampaignDashboard,
    CampaignCreate,
    CampaignList,
    LeadDashboard,
    LeadCreate,
    LeadList,
    LeadDetail,
    PartnerDashboard,
    PartnerCreate,
    PartnerList,
    PartnerDetail,
} from "./pages";

function App() {
    const dispatch = useDispatch();
    const auth_token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const authTokenstore = useSelector((states) => states?.appStore?.authToken);
    const userIdStore = useSelector((states) => states?.appStore?.userId);

    if (userIdStore && !userId) {
        localStorage.setItem("userId", userIdStore);
    } else if (!userIdStore && userId) {
        var formatedData = {
            userId: userId,
        };
        dispatch(mergeStore(formatedData));
    }

    if (authTokenstore && !auth_token) {
        localStorage.setItem("authToken", authTokenstore);
    } else if (!authTokenstore && auth_token) {
        var formatedData = {
            authToken: auth_token,
        };
        dispatch(mergeStore(formatedData));
    }
    const authToken = authTokenstore ? authTokenstore : auth_token;

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes>
                    <Route path="/login" element={<Login />}></Route>

                    <Route
                        path="/"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <Home />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/my/dashboard"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <MyDashboard />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/my/tasks"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <MyTasks />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/employeetarget/create"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <EmployeeTargetCreate />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/campaign/dashboard"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <CampaignDashboard />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/campaign/create"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <CampaignCreate />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/campaign/list"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <CampaignList />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/lead/dashboard"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <LeadDashboard />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/lead/create"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <LeadCreate />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/lead/list"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <LeadList />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/lead/:id"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <LeadDetail />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/partner/dashboard"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <PartnerDashboard />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/partner/create"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <PartnerCreate />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/partner/list"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <PartnerList />
                            </AuthenticatedRoute>
                        }
                    ></Route>

                    <Route
                        path="/partner/:id"
                        element={
                            <AuthenticatedRoute isAuthenticated={authToken}>
                                <PartnerDetail />
                            </AuthenticatedRoute>
                        }
                    ></Route>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
