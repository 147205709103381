import { configureStore } from "@reduxjs/toolkit";
import appStoreReducer from "./appSlice";

import { stateListSlice } from "./stateListSlice";

import { stateDetailSlice } from "./stateDetailSlice";

import { leadListSlice } from "./leadListSlice";

import { leadCreateSlice } from "./leadCreateSlice";

import { leadDetailSlice } from "./leadDetailSlice";

import { employeeListSlice } from "./employeeListSlice";

import { employeeDetailSlice } from "./employeeDetailSlice";

import { employeeTargetListSlice } from "./employeeTargetListSlice";

import { employeeTargetCreateSlice } from "./employeeTargetCreateSlice";

import { campaignListSlice } from "./campaignListSlice";

import { campaignCreateSlice } from "./campaignCreateSlice";

import { campaignDetailSlice } from "./campaignDetailSlice";

import { interactionListSlice } from "./interactionListSlice";

import { interactionCreateSlice } from "./interactionCreateSlice";

import { noteCreateSlice } from "./noteCreateSlice";

import { noteListSlice } from "./noteListSlice";

import { partnerListSlice } from "./partnerListSlice";

import { partnerCreateSlice } from "./partnerCreateSlice";

import { partnerDetailSlice } from "./partnerDetailSlice";

import { loginApiSlice } from "./loginApiSlice";

export default configureStore({
    reducer: {
        appStore: appStoreReducer,
        [stateListSlice.reducerPath]: stateListSlice.reducer,
        [stateDetailSlice.reducerPath]: stateDetailSlice.reducer,
        [leadListSlice.reducerPath]: leadListSlice.reducer,
        [leadCreateSlice.reducerPath]: leadCreateSlice.reducer,
        [leadDetailSlice.reducerPath]: leadDetailSlice.reducer,
        [employeeListSlice.reducerPath]: employeeListSlice.reducer,
        [employeeDetailSlice.reducerPath]: employeeDetailSlice.reducer,
        [employeeTargetListSlice.reducerPath]: employeeTargetListSlice.reducer,
        [employeeTargetCreateSlice.reducerPath]: employeeTargetCreateSlice.reducer,
        [campaignListSlice.reducerPath]: campaignListSlice.reducer,
        [campaignCreateSlice.reducerPath]: campaignCreateSlice.reducer,
        [campaignDetailSlice.reducerPath]: campaignDetailSlice.reducer,
        [interactionListSlice.reducerPath]: interactionListSlice.reducer,
        [interactionCreateSlice.reducerPath]: interactionCreateSlice.reducer,
        [noteCreateSlice.reducerPath]: noteCreateSlice.reducer,
        [noteListSlice.reducerPath]: noteListSlice.reducer,
        [partnerListSlice.reducerPath]: partnerListSlice.reducer,
        [partnerCreateSlice.reducerPath]: partnerCreateSlice.reducer,
        [partnerDetailSlice.reducerPath]: partnerDetailSlice.reducer,
        [loginApiSlice.reducerPath]: loginApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            stateListSlice.middleware,
            stateDetailSlice.middleware,
            leadListSlice.middleware,
            leadCreateSlice.middleware,
            leadDetailSlice.middleware,
            employeeListSlice.middleware,
            employeeDetailSlice.middleware,
            employeeTargetListSlice.middleware,
            employeeTargetCreateSlice.middleware,
            campaignListSlice.middleware,
            campaignCreateSlice.middleware,
            campaignDetailSlice.middleware,
            interactionListSlice.middleware,
            interactionCreateSlice.middleware,
            noteCreateSlice.middleware,
            noteListSlice.middleware,
            partnerListSlice.middleware,
            partnerCreateSlice.middleware,
            partnerDetailSlice.middleware,
            loginApiSlice.middleware
        ),
});
