import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const campaignListSlice = createApi({
    reducerPath: "campaignList",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        campaignList: builder.query({
            query: (data) => ({ url: "/acquisition/api/v1/campaign/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useCampaignListQuery } = campaignListSlice;
