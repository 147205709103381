import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const leadDetailSlice = createApi({
    reducerPath: "leadDetail",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        leadDetail: builder.query({
            query: (data) => ({ url: "/acquisition/api/v1/lead/detail", params: data, method: "GET" }),
        }),
    }),
});

export const { useLeadDetailQuery } = leadDetailSlice;
