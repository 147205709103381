// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, DetailWidget, FormWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useStateDetailQuery } from "../store/stateDetailSlice";

import { useLeadDetailQuery } from "../store/leadDetailSlice";

import { useInteractionListQuery } from "../store/interactionListSlice";

import { useNoteListQuery } from "../store/noteListSlice";

import { useNoteCreateMutation } from "../store/noteCreateSlice";

import { useInteractionCreateMutation } from "../store/interactionCreateSlice";
import { default as leadDetailFormatter } from "../transformations/leadDetailFormatter";
function LeadDetail() {
    const leadData = useSelector((states) => states?.appStore?.leadData);
    const noteListData = useSelector((states) => states?.appStore?.noteListData);
    const interactionListData = useSelector((states) => states?.appStore?.interactionListData);
    const [stateDetailSkip, setstateDetailSkip] = useState(true);
    const [stateDetailParams, setstateDetailParams] = useState({});
    const stateDetailQuery = useStateDetailQuery(stateDetailParams, { skip: stateDetailSkip });
    const [leadDetailSkip, setleadDetailSkip] = useState(true);
    const [leadDetailParams, setleadDetailParams] = useState({});
    const leadDetailQuery = useLeadDetailQuery(leadDetailParams, { skip: leadDetailSkip });
    const [interactionListSkip, setinteractionListSkip] = useState(true);
    const [interactionListParams, setinteractionListParams] = useState({});
    const interactionListQuery = useInteractionListQuery(interactionListParams, { skip: interactionListSkip });
    const [noteListSkip, setnoteListSkip] = useState(true);
    const [noteListParams, setnoteListParams] = useState({});
    const noteListQuery = useNoteListQuery(noteListParams, { skip: noteListSkip });
    const noteCreateMutation = useNoteCreateMutation();
    const interactionCreateMutation = useInteractionCreateMutation();
    const dispatch = useDispatch();
    const currentState = useSelector((states) => states?.appStore?.currentState);
    const userId = useSelector((states) => states?.appStore?.userId);
    const { id } = useParams();

    const afternoteupdate = () => {
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    };

    const interactionCreate = async (data) => {
        await interactionCreateMutation[0](data).unwrap();
    };
    const addInteractionApiSuccess = (data) => {
        afternoteupdate(data);
    };
    useEffect(() => {
        if (interactionCreateMutation[1].isSuccess) {
            addInteractionApiSuccess(interactionCreateMutation[1]);
        }
    }, [interactionCreateMutation]);

    const addInteractionForm = (data) => {
        interactionCreate(data);
    };

    const noteCreate = async (data) => {
        await noteCreateMutation[0](data).unwrap();
    };
    const addnotesApiSuccess = (data) => {
        afternoteupdate(data);
    };
    useEffect(() => {
        if (noteCreateMutation[1].isSuccess) {
            addnotesApiSuccess(noteCreateMutation[1]);
        }
    }, [noteCreateMutation]);

    const addnotesform = (data) => {
        noteCreate(data);
    };

    const savenoteListData = (data) => {
        var formatedData = {
            noteListData: data.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const loadNotesSuccess = (data) => {
        savenoteListData(data);
    };
    useEffect(() => {
        if (noteListQuery.isSuccess) {
            loadNotesSuccess(noteListQuery);
        }
    }, [noteListQuery]);
    const noteList = () => {
        setnoteListParams({ contact_id: id });
        setnoteListSkip(false);
    };

    useEffect(() => {
        noteList();
    }, [id]);

    const saveInteractionListData = (data) => {
        var formatedData = {
            interactionListData: data.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const loadLeadInteractionsSuccess = (data) => {
        saveInteractionListData(data);
    };
    useEffect(() => {
        if (interactionListQuery.isSuccess) {
            loadLeadInteractionsSuccess(interactionListQuery);
        }
    }, [interactionListQuery]);
    const interactionList = () => {
        setinteractionListParams({ contact_id: id });
        setinteractionListSkip(false);
    };

    useEffect(() => {
        interactionList();
    }, [id]);

    const saveStateData = (data) => {
        var formatedData = {
            currentState: data.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const loadStateDetailSuccess = (data) => {
        saveStateData(data);
    };
    useEffect(() => {
        if (stateDetailQuery.isSuccess) {
            loadStateDetailSuccess(stateDetailQuery);
        }
    }, [stateDetailQuery]);
    const stateDetail = () => {
        setstateDetailParams({ id: leadData?.state });
        setstateDetailSkip(false);
    };

    const saveLeadData = (data) => {
        var formatedData = {
            ...leadDetailFormatter(data.data || data, "leadData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadLeadDataSuccess = (data) => {
        saveLeadData(data);
        stateDetail(data);
        loadStateDetailSuccess(data);
        saveStateData(data);
    };
    useEffect(() => {
        if (leadDetailQuery.isSuccess) {
            loadLeadDataSuccess(leadDetailQuery);
        }
    }, [leadDetailQuery]);
    const leadDetail = () => {
        setleadDetailParams({ id: id });
        setleadDetailSkip(false);
    };

    useEffect(() => {
        leadDetail();
    }, [id, leadData?.state]);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} md={12}>
                    <DetailWidget
                        direction="row"
                        header="Basic Information"
                        md={12}
                        data={leadData || []}
                        fields={[
                            { label: "Name", key: "full_name" },
                            { label: "Phone", key: "phone" },
                            { label: "Email", key: "email" },
                            { label: "City", key: "city" },
                            { label: "State", key: currentState?.name },
                            { label: "Pin Code", key: "pincode" },
                            { label: "Languages", key: "languages_known" },
                        ]}
                    ></DetailWidget>
                </PMGrid>

                <PMGrid container={true} md={12} marginTop={2}>
                    <PMGrid container={true} direction="column" md={6}>
                        <FormWidget
                            direction="column"
                            header="Note"
                            fullWidth={true}
                            defaultValues={{ lead_id: id, employee_id: userId }}
                            fieldsets={[
                                {
                                    fields: [
                                        {
                                            label: "Add Note",
                                            name: "note",
                                            type: "text",
                                            multiline: true,
                                            rows: 3,
                                            required: true,
                                        },
                                        { label: "Lead ID", name: "contact_id", type: "hidden", required: true },
                                        { label: "Employee ID", name: "employee_id", type: "hidden", required: true },
                                    ],
                                },
                            ]}
                            Onsubmit={addnotesform}
                        ></FormWidget>

                        <TableWidget
                            header="Notes"
                            rows={noteListData || []}
                            columns={[
                                { field: "created_by", headerName: "By" },
                                { field: "created_at", headerName: "Time" },
                                { field: "content", headerName: "Note" },
                            ]}
                        ></TableWidget>
                    </PMGrid>

                    <PMGrid container={true} direction="column" md={6}>
                        <FormWidget
                            header="Messaging"
                            fullWidth={true}
                            defaultValues={{ contact_id: id }}
                            fieldsets={[
                                {
                                    fields: [
                                        {
                                            label: "Mode",
                                            name: "type",
                                            type: "select",
                                            options: [
                                                { id: "whatsapp", label: "WhatsApp" },
                                                { id: "email", label: "Email" },
                                                { id: "sms", label: "SMS" },
                                            ],
                                            required: true,
                                        },
                                        {
                                            label: "Message",
                                            name: "content",
                                            type: "text",
                                            multiline: true,
                                            rows: 3,
                                            required: true,
                                        },
                                        { label: "Lead ID", name: "contact_id", type: "hidden", required: true },
                                    ],
                                },
                            ]}
                            Onsubmit={addInteractionForm}
                        ></FormWidget>

                        <TableWidget
                            header="Interactions History"
                            rows={interactionListData || []}
                            columns={[
                                { field: "type", headerName: "Name" },
                                { field: "done_datetime", headerName: "Time" },
                                { field: "employee_id", headerName: "Done By" },
                            ]}
                        ></TableWidget>
                    </PMGrid>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default LeadDetail;
