

const leadDetailFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data));
    dataSet[dataKey]["full_name"] = dataSet[dataKey]["first_name"] + " " + dataSet[dataKey]["last_name"];
    dataSet[dataKey]["address"] = dataSet[dataKey]["address_line_1"] + "," + dataSet[dataKey]["address_line_2"];
    return dataSet;
};

export default leadDetailFormatter;
