import { Paper, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import React, { ReactNode } from "react";
import { PMGrid, PMText } from "../../pmcomponents";
import { PMGridProps } from "../../pmcomponents/pmgrid/PMGrid";

export interface BaseWidgetProps extends PMGridProps {
    header?: string
    children?: ReactNode
    bodyPadding: number
    bgColor?: string
    headerDividerColor?: string
    elevation?: number
    direction?: any
    headerColor?: string
}


export const BaseWidget = (props:BaseWidgetProps) => {
    const {bgColor, sx, bodyPadding, headerDividerColor, ...baseProps } = props;

    let cardStyleProps = {
        bgcolor: bgColor,
        ...sx
    }

    // All widgets are grid items by default
    return (
        <PMGrid item {...baseProps}>
            <Paper sx={cardStyleProps} elevation={props.elevation}>
                <Stack direction="column">
                    {
                        props.header &&
                            <Box sx={{ py: 1, px: 2, borderBottom: 1, borderColor: headerDividerColor }}>
                                <PMText variant="body1" sx={{ fontWeight: "600" }} color={props.headerColor}>{props.header}</PMText>
                            </ Box>
                    }
                    <Box sx={{width: "100%", padding: bodyPadding}}>
                        {props?.children}
                    </ Box>
                </ Stack>
            </ Paper>
        </PMGrid>
    )
}

BaseWidget.defaultProps = {
    bgColor: "background.paper",
    headerDividerColor: "grey.200",
    elevation: 1,
    bodyPadding: 2
}

export default BaseWidget