const requireValidator = (fieldValue) => {
    fieldValue = fieldValue && fieldValue.trim ? fieldValue.trim() : fieldValue;
    return {
        valid:
        fieldValue !== null &&
        fieldValue !== "" &&
        typeof fieldValue !== "undefined",
        validationText: "This field is mandatory"
    };
};
const minLengthValidator = (fieldValue, minLength, msg) => {
    return {
        valid:
            fieldValue !== null &&
            fieldValue !== "" &&
            typeof fieldValue !== "undefined" 
            && fieldValue.toString().length > minLength,
        validationText: msg? msg : `This field should have minimum ${minLength} chars`
    };
};

const emailValidator = (fieldValue, regexdata, msg) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return {
        valid: re.test(fieldValue),
        validationText: msg ? msg : "the email address is not valid"
    };
};
const maxLengthValidator = (fieldValue, maxLength, msg) => {
    return {
        valid:
            fieldValue !== null &&
            fieldValue !== "" &&
            typeof fieldValue !== "undefined" 
            && fieldValue.toString().length < maxLength,
        validationText: msg ? msg : `This field should have maximum ${maxLength} chars`
    };
};

const patterValidator = (fieldValue, regexdata, msg) => {
    return {
        valid: regexdata.test(fieldValue),
        validationText: msg ? msg : "the field is not in proper format"
    };
};

  const formValidators = {
    requireValidator: requireValidator,
    minLengthValidator: minLengthValidator,
    maxLengthValidator: maxLengthValidator,
    emailValidator: emailValidator,
    patterValidator: patterValidator
  }

  export default formValidators