import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const noteListSlice = createApi({
    reducerPath: "noteList",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        noteList: builder.query({
            query: (data) => ({ url: "/interactions/api/v1/note/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useNoteListQuery } = noteListSlice;
