import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const campaignDetailSlice = createApi({
    reducerPath: "campaignDetail",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        campaignDetail: builder.query({
            query: (data) => ({ url: "/acquisition/api/v1/campaign/detail", params: data, method: "GET" }),
        }),
    }),
});

export const { useCampaignDetailQuery } = campaignDetailSlice;
