// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useCampaignListQuery } from "../store/campaignListSlice";

import { useLeadCreateMutation } from "../store/leadCreateSlice";
import { default as campaignListFormatter } from "../transformations/campaignListFormatter";
function LeadCreate() {
    const [campaignListSkip, setcampaignListSkip] = useState(true);
    const [campaignListParams, setcampaignListParams] = useState({});
    const campaignListQuery = useCampaignListQuery(campaignListParams, { skip: campaignListSkip });
    const leadCreateMutation = useLeadCreateMutation();
    const dispatch = useDispatch();
    const campaignListData = useSelector((states) => states?.appStore?.campaignListData);

    const leadCreate = async (data) => {
        await leadCreateMutation[0](data).unwrap();
    };
    const leadCreateApiSuscess = (data) => {};
    useEffect(() => {
        if (leadCreateMutation[1].isSuccess) {
            leadCreateApiSuscess(leadCreateMutation[1]);
        }
    }, [leadCreateMutation]);

    const createlead = (data) => {
        leadCreate(data);
    };

    const saveCampaignListData = (data) => {
        var formatedData = {
            ...campaignListFormatter(data.data || data, "campaignListData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadCampaignDataSuccess = (data) => {
        saveCampaignListData(data);
    };
    useEffect(() => {
        if (campaignListQuery.isSuccess) {
            loadCampaignDataSuccess(campaignListQuery);
        }
    }, [campaignListQuery]);
    const campaignList = () => {
        setcampaignListSkip(false);
    };

    useEffect(() => {
        campaignList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Create New Lead"
                        fieldsets={[
                            {
                                direction: "row",
                                fields: [
                                    { label: "First Name", name: "first_name", type: "text", required: true },
                                    { label: "Last Name", name: "last_name", type: "text", required: true },
                                    { label: "Phone", name: "phone", type: "text", required: true },
                                    { label: "Email", name: "email", type: "text", required: true },
                                ],
                            },
                            {
                                direction: "row",
                                fields: [
                                    { label: "Pincode", name: "pincode", type: "text", required: true },
                                    {
                                        label: "Campaign",
                                        name: "campaign_id",
                                        type: "select",
                                        required: true,
                                        options: campaignListData,
                                    },
                                ],
                            },
                        ]}
                        Onsubmit={createlead}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default LeadCreate;
