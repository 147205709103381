// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, SimpleMetricWidget, MetricWidget, ChartWidget, PMHeader } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useEmployeeTargetListQuery } from "../store/employeeTargetListSlice";

import { useEmployeeListQuery } from "../store/employeeListSlice";
import { default as employeeDetailFormatter } from "../transformations/employeeDetailFormatter";
import { default as employeeTargetFormatter } from "../transformations/employeeTargetFormatter";
function MyDashboard() {
    const userId = useSelector((states) => states?.appStore?.userId);
    const employeeData = useSelector((states) => states?.appStore?.employeeData);
    const [employeeTargetListSkip, setemployeeTargetListSkip] = useState(true);
    const [employeeTargetListParams, setemployeeTargetListParams] = useState({});
    const employeeTargetListQuery = useEmployeeTargetListQuery(employeeTargetListParams, {
        skip: employeeTargetListSkip,
    });
    const [employeeListSkip, setemployeeListSkip] = useState(true);
    const [employeeListParams, setemployeeListParams] = useState({});
    const employeeListQuery = useEmployeeListQuery(employeeListParams, { skip: employeeListSkip });
    const dispatch = useDispatch();

    const saveEmployeeTargetData = (data) => {
        var formatedData = {
            ...employeeTargetFormatter(data.data || data, "employeeTargetData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadEmployeeTargetSuccess = (data) => {
        saveEmployeeTargetData(data);
    };
    useEffect(() => {
        if (employeeTargetListQuery.isSuccess) {
            loadEmployeeTargetSuccess(employeeTargetListQuery);
        }
    }, [employeeTargetListQuery]);
    const employeeTargetList = () => {
        setemployeeTargetListParams({ employee_id: employeeData?.id });
        setemployeeTargetListSkip(false);
    };

    const saveEmployeeData = (data) => {
        var formatedData = {
            ...employeeDetailFormatter(data.data || data, "employeeData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadEmployeeDataSuccess = (data) => {
        saveEmployeeData(data);
        employeeTargetList(data);
        loadEmployeeTargetSuccess(data);
        saveEmployeeTargetData(data);
    };
    useEffect(() => {
        if (employeeListQuery.isSuccess) {
            loadEmployeeDataSuccess(employeeListQuery);
        }
    }, [employeeListQuery]);
    const employeeList = () => {
        setemployeeListParams({ user_id: userId });
        setemployeeListSkip(false);
    };

    useEffect(() => {
        employeeList();
    }, [userId, employeeData?.id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} spacing={2} alignItems="stretch">
                    <SimpleMetricWidget
                        md={3}
                        metricName="Total Target"
                        metricValue="150"
                        metricSubtext="Number of Partners"
                        metricIcon="people"
                        metricIconColor="success.light"
                    ></SimpleMetricWidget>

                    <SimpleMetricWidget
                        md={3}
                        metricName="Achievement"
                        metricValue="75"
                        metricSubtext="Month Till Date"
                        metricIcon="people"
                        metricIconColor="error.light"
                    ></SimpleMetricWidget>

                    <SimpleMetricWidget
                        md={3}
                        metricName="Rating"
                        metricValue="3"
                        metricIcon="favorite"
                        metricIconColor="warning.light"
                    ></SimpleMetricWidget>

                    <SimpleMetricWidget
                        md={3}
                        metricName="Incentive"
                        metricPrefix="₹"
                        metricValue="5,000"
                        metricIcon="money"
                        metricSubtext="Accrual for the Month"
                    ></SimpleMetricWidget>
                </PMGrid>

                <PMGrid container={true} spacing={2} marginTop={4} alignItems="stretch">
                    <MetricWidget md={4} header="Converted Leads" metricValue="75"></MetricWidget>

                    <MetricWidget md={4} header="Open Leads" metricValue="150"></MetricWidget>

                    <ChartWidget
                        md={2}
                        type="pie"
                        header="Leads by Category"
                        labels={["Hot", "Warm", "Cold"]}
                        showTooltip={true}
                        datasets={[[30, 100, 20]]}
                    ></ChartWidget>
                </PMGrid>

                <PMHeader header="Lead Milestones"></PMHeader>

                <PMGrid container={true} spacing={2}>
                    <MetricWidget md={3} header="New" metricValue="25" metricIcon="call"></MetricWidget>

                    <MetricWidget md={3} header="Registered" metricValue="75" metricIcon="people"></MetricWidget>

                    <MetricWidget md={3} header="Training Done" metricValue="50" metricIcon="get_app"></MetricWidget>

                    <MetricWidget md={3} header="Exam Passed" metricValue="75" metricIcon="done"></MetricWidget>
                </PMGrid>

                <PMHeader header="Today's Activities"></PMHeader>

                <PMGrid container={true} spacing={3}>
                    <MetricWidget
                        md={2}
                        header="Email"
                        metricValue="25"
                        metricIcon="email"
                        metricIconColor="success"
                    ></MetricWidget>

                    <MetricWidget
                        md={2}
                        header="Whatsapp"
                        metricValue="75"
                        metricIcon="people"
                        metricIconColor="warning"
                    ></MetricWidget>

                    <MetricWidget
                        md={2}
                        header="SMS"
                        metricValue="50"
                        metricIcon="sms"
                        metricIconColor="primary"
                    ></MetricWidget>

                    <MetricWidget
                        md={2}
                        header="Call"
                        metricValue="75"
                        metricIcon="call"
                        metricIconColor="error"
                    ></MetricWidget>

                    <MetricWidget md={2} header="Meeting" metricValue="75" metricIcon="people"></MetricWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default MyDashboard;
