// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useLeadCreateMutation } from "../store/leadCreateSlice";

function PartnerCreate() {
    const thisLead = useSelector((states) => states?.appStore?.thisLead);
    const leadCreateMutation = useLeadCreateMutation();

    const leadCreate = async (data) => {
        await leadCreateMutation[0](data).unwrap();
    };
    const leadCreateApiSuscess = (data) => {};
    useEffect(() => {
        if (leadCreateMutation[1].isSuccess) {
            leadCreateApiSuscess(leadCreateMutation[1]);
        }
    }, [leadCreateMutation]);

    const createlead = (data) => {
        leadCreate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Create New Lead"
                        data={thisLead || []}
                        fields={[
                            { label: "First Name", name: "first_name", type: "text", required: true },
                            { label: "Last Name", name: "last_name", type: "text", required: true },
                            { label: "Phone", name: "phone", type: "text", required: true },
                            { label: "Email", name: "email", type: "text", required: true },
                            { label: "Pincode", name: "pincode", type: "text", required: true },
                            { label: "City", name: "city", type: "select", required: true },
                        ]}
                        Onsubmit={createlead}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default PartnerCreate;
