import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const stateListSlice = createApi({
    reducerPath: "stateList",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        stateList: builder.query({
            query: (data) => ({ url: "/org/api/v1/state/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useStateListQuery } = stateListSlice;
