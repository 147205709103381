import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const campaignCreateSlice = createApi({
    reducerPath: "campaignCreate",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        campaignCreate: builder.mutation({
            query: (data) => ({ url: "/acquisition/api/v1/campaign/create", method: "POST", body: data }),
        }),
    }),
});

export const { useCampaignCreateMutation } = campaignCreateSlice;
