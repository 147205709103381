// import { Drawer } from "@mui/material";
// import Divider from '@mui/material/Divider';
// import { MenuProps } from "@mui/material/Menu";
// import { styled, useTheme } from '@mui/material/styles';
// import React from "react";
// import { AvatarProps, default as Avatar } from "../avatar";
// import { default as PMList, listData } from "../pmlist";
// import "./PMMenu.scss";

// export interface PMMenuProps {
//   // grid?: number
//   menuItems: Array<listData>
//   avatar: AvatarProps
//   // label?: string
// }

// const PMMenu = (props: PMMenuProps & MenuProps) => {
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };
//   const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'center',
//   }));

//   return (
//     <Drawer anchor="left" open={true} variant="permanent" ModalProps={{
//       keepMounted: true,
//     }}>
//         <DrawerHeader>
//           <Avatar {...props.avatar}></Avatar>
//         </DrawerHeader>
//         <Divider />

//       <PMList grid="12" data={props.menuItems}></PMList>
//     </Drawer>
//   );
// };

// export default PMMenu;

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import MailIcon from '@mui/icons-material/Mail';
// import MenuIcon from '@mui/icons-material/Menu';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Badge from '@mui/material/Badge';
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Menu from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import { PMGrid, PMIcon } from "../../pmcomponents";
import { Avatar } from "../avatar";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function PMMenu(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleClick = (url: string) => {
        if (props.onClick) {
            props.onClick({ url: url });
        }
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                        >
                        Permute
                    </Typography>

                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>

                    <PMGrid align_items={"right"} grid={12}>
                        <Avatar
                            text="A"
                            size="large"
                            text_color="primary"
                            shape="square"
                            background_color="secondary"
                            emphasis="bold"
                        ></Avatar>
                    </PMGrid>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <Toolbar>
                        <img src={props.logo} style={{ maxWidth: "100%", maxHeight: "100%" }}></img>
                        {/* <img src="logo.png" alt="logo" className={classes.logo} /> */}
                    </Toolbar>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {props?.menuItems.map((item, index) => {
                        let key = "li" + index;
                        switch (item?.type) {
                            case "Divider":
                                return <Divider key={key}/>;
                            case "Subheader":
                                return <ListSubheader component="div" key={key}>{item.text}</ListSubheader>;
                            default:
                                return (
                                    <ListItem onClick={() => handleClick(item?.url)} key={key} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <PMIcon icon={item.icon.icon}></PMIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                        }
                    })}
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {props?.children}
            </Main>
        </Box>
    );
}
