// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, MetricWidget, PMStack, PMText, PMDropdown, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useLeadListQuery } from "../store/leadListSlice";

import { useStateListQuery } from "../store/stateListSlice";
import { default as leadListFormatter } from "../transformations/leadListFormatter";
import { default as stateListFormatter } from "../transformations/stateListFormatter";
function LeadList() {
    const filterStateData = useSelector((states) => states?.appStore?.filterStateData);
    const leadCount = useSelector((states) => states?.appStore?.leadCount);
    const stateListData = useSelector((states) => states?.appStore?.stateListData);
    const leadData = useSelector((states) => states?.appStore?.leadData);
    const [leadListSkip, setleadListSkip] = useState(true);
    const [leadListParams, setleadListParams] = useState({});
    const leadListQuery = useLeadListQuery(leadListParams, { skip: leadListSkip });
    const [stateListSkip, setstateListSkip] = useState(true);
    const [stateListParams, setstateListParams] = useState({});
    const stateListQuery = useStateListQuery(stateListParams, { skip: stateListSkip });
    const history = useNavigate();
    const dispatch = useDispatch();

    const afterLogin = (data) => {
        history("/lead" + "/" + data["id"]);
    };

    const LeadTableClick = (data) => {
        afterLogin(data);
    };

    const stateFilterDataSave = (data) => {
        var formatedData = {
            filterStateData: data.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const filterStateChange = (data) => {
        stateFilterDataSave(data);
    };

    const saveStateListData = (data) => {
        var formatedData = {
            ...stateListFormatter(data.data || data, "stateListData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadStateListSuccess = (data) => {
        saveStateListData(data);
    };
    useEffect(() => {
        if (stateListQuery.isSuccess) {
            loadStateListSuccess(stateListQuery);
        }
    }, [stateListQuery]);
    const stateList = () => {
        setstateListSkip(false);
    };

    useEffect(() => {
        stateList();
    }, []);

    const saveLeadData = (data) => {
        var formatedData = {
            ...leadListFormatter(data.data || data, "leadData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadLeadDataSuccess = (data) => {
        saveLeadData(data);
    };
    useEffect(() => {
        if (leadListQuery.isSuccess) {
            loadLeadDataSuccess(leadListQuery);
        }
    }, [leadListQuery]);
    const leadList = () => {
        setleadListParams({ state: filterStateData });
        setleadListSkip(false);
    };

    useEffect(() => {
        leadList();
    }, [filterStateData]);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} spacing={4}>
                    <MetricWidget xs={4} header="Total Leads" metricValue={leadCount || []}></MetricWidget>

                    <MetricWidget xs={4} header="Sample Number" metricValue="24"></MetricWidget>

                    <MetricWidget xs={4} header="Sample Metric" metricValue="24"></MetricWidget>
                </PMGrid>

                <PMStack marginTop={2}>
                    <PMText variant="body1" sm={2} children="Filters"></PMText>

                    <PMDropdown
                        sm={4}
                        label="State"
                        options={stateListData || []}
                        Onchange={filterStateChange}
                    ></PMDropdown>
                </PMStack>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="All Leads"
                        showSerialNumber={true}
                        xs={12}
                        rows={leadData || []}
                        columns={[
                            { field: "full_name", headerName: "Name" },
                            { field: "phone", headerName: "Phone" },
                            { field: "email", headerName: "Email" },
                            { field: "address", headerName: "Address" },
                            { field: "pincode", headerName: "Pin Code" },
                            { field: "assigned_date", headerName: "Assigned On", type: "date" },
                        ]}
                        Onclick={LeadTableClick}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default LeadList;
