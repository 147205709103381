import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const partnerListSlice = createApi({
    reducerPath: "partnerList",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        partnerList: builder.query({
            query: (data) => ({ url: "/acquisition/api/v1/campaign/list", params: data, method: "GET" }),
        }),
    }),
});

export const { usePartnerListQuery } = partnerListSlice;
