import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const leadListSlice = createApi({
    reducerPath: "leadList",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        leadList: builder.query({
            query: (data) => ({ url: "/acquisition/api/v1/lead/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useLeadListQuery } = leadListSlice;
