import React from "react";

// type FormContextType = {
//     getFieldValue?:any
//     getFieldValidationState?:any
//     getFieldTouchedState?:any
//     setFieldValue?:any
//     setFieldTouchState?:any
//     validateField?:any
//     submitOccurred?:any 
//   };
const FormContext = React.createContext(undefined);

export default FormContext;
