import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const interactionListSlice = createApi({
    reducerPath: "interactionList",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        interactionList: builder.query({
            query: (data) => ({ url: "/interactions/api/v1/interaction/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useInteractionListQuery } = interactionListSlice;
