// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, ChartWidget, EventCalendarWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function MyTasks() {
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true}>
                    <ChartWidget
                        sm={3}
                        header="Campaign Performance"
                        type="bar"
                        datasets={[
                            [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                            [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                        ]}
                    ></ChartWidget>

                    <ChartWidget
                        sm={3}
                        header="Campaign Performance"
                        type="pie"
                        datasets={[[12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3]]}
                    ></ChartWidget>

                    <EventCalendarWidget
                        sm={6}
                        events={[
                            {
                                id: 0,
                                title: "All Day Event very long title",
                                allDay: true,
                                start: "2015-03-30T18:30:00.000Z",
                                end: "2015-03-31T18:30:00.000Z",
                            },
                            {
                                id: 1,
                                title: "Long Event",
                                start: "2022-10-06T18:30:00.000Z",
                                end: "2022-10-09T18:30:00.000Z",
                            },
                            {
                                id: 2,
                                title: "DTS STARTS",
                                start: "2016-03-12T18:30:00.000Z",
                                end: "2016-03-19T18:30:00.000Z",
                            },
                            {
                                id: 3,
                                title: "DTS ENDS",
                                start: "2016-11-05T18:30:00.000Z",
                                end: "2016-11-12T18:30:00.000Z",
                            },
                            {
                                id: 4,
                                title: "Some Event",
                                start: "2022-10-08T18:30:00.000Z",
                                end: "2022-10-09T18:30:00.000Z",
                            },
                            {
                                id: 5,
                                title: "Conference",
                                start: "2022-10-10T18:30:00.000Z",
                                end: "2022-10-12T18:30:00.000Z",
                                desc: "Big conference for important people",
                            },
                            {
                                id: 6,
                                title: "Meeting",
                                start: "2022-10-12T05:00:00.000Z",
                                end: "2022-10-12T07:00:00.000Z",
                                desc: "Pre-meeting meeting, to prepare for the meeting",
                            },
                            {
                                id: 7,
                                title: "Lunch",
                                start: "2022-10-12T06:30:00.000Z",
                                end: "2022-10-12T07:30:00.000Z",
                                desc: "Power lunch",
                            },
                            {
                                id: 8,
                                title: "Meeting",
                                start: "2022-10-12T08:30:00.000Z",
                                end: "2022-10-12T09:30:00.000Z",
                            },
                            {
                                id: 9,
                                title: "Happy Hour",
                                start: "2022-10-12T11:30:00.000Z",
                                end: "2022-10-12T12:00:00.000Z",
                                desc: "Most important meal of the day",
                            },
                            {
                                id: 10,
                                title: "Dinner",
                                start: "2022-10-12T14:30:00.000Z",
                                end: "2022-10-12T15:30:00.000Z",
                            },
                            {
                                id: 11,
                                title: "Planning Meeting with Paige",
                                start: "2022-10-13T02:30:00.000Z",
                                end: "2022-10-13T05:00:00.000Z",
                            },
                            {
                                id: 11.1,
                                title: "Inconvenient Conference Call",
                                start: "2022-10-13T04:00:00.000Z",
                                end: "2022-10-13T06:30:00.000Z",
                            },
                            {
                                id: 11.2,
                                title: "Project Kickoff - Lou's Shoes",
                                start: "2022-10-13T06:00:00.000Z",
                                end: "2022-10-13T08:30:00.000Z",
                            },
                            {
                                id: 11.3,
                                title: "Quote Follow-up - Tea by Tina",
                                start: "2022-10-13T10:00:00.000Z",
                                end: "2022-10-13T10:30:00.000Z",
                            },
                            {
                                id: 12,
                                title: "Late Night Event",
                                start: "2022-10-17T14:00:00.000Z",
                                end: "2022-10-17T20:30:00.000Z",
                            },
                            {
                                id: 12.5,
                                title: "Late Same Night Event",
                                start: "2022-10-17T14:00:00.000Z",
                                end: "2022-10-17T18:00:00.000Z",
                            },
                            {
                                id: 13,
                                title: "Multi-day Event",
                                start: "2022-10-20T14:00:00.000Z",
                                end: "2022-10-21T20:30:00.000Z",
                            },
                            {
                                id: 14,
                                title: "Today",
                                start: "2022-10-13T08:08:55.280Z",
                                end: "2022-10-13T14:08:55.280Z",
                            },
                            {
                                id: 15,
                                title: "Point in Time Event",
                                start: "2022-10-13T11:08:52.283Z",
                                end: "2022-10-13T11:08:52.283Z",
                            },
                            {
                                id: 16,
                                title: "Video Record",
                                start: "2022-10-14T10:00:00.000Z",
                                end: "2022-10-14T13:30:00.000Z",
                            },
                            {
                                id: 17,
                                title: "Dutch Song Producing",
                                start: "2022-10-14T11:00:00.000Z",
                                end: "2022-10-14T14:30:00.000Z",
                            },
                            {
                                id: 18,
                                title: "Itaewon Halloween Meeting",
                                start: "2022-10-14T11:00:00.000Z",
                                end: "2022-10-14T12:00:00.000Z",
                            },
                            {
                                id: 19,
                                title: "Online Coding Test",
                                start: "2022-10-14T12:00:00.000Z",
                                end: "2022-10-14T15:00:00.000Z",
                            },
                            {
                                id: 20,
                                title: "An overlapped Event",
                                start: "2022-10-14T11:30:00.000Z",
                                end: "2022-10-14T13:00:00.000Z",
                            },
                            {
                                id: 21,
                                title: "Phone Interview",
                                start: "2022-10-14T11:30:00.000Z",
                                end: "2022-10-14T13:00:00.000Z",
                            },
                            {
                                id: 22,
                                title: "Cooking Class",
                                start: "2022-10-14T12:00:00.000Z",
                                end: "2022-10-14T13:30:00.000Z",
                            },
                            {
                                id: 23,
                                title: "Go to the gym",
                                start: "2022-10-14T13:00:00.000Z",
                                end: "2022-10-14T14:30:00.000Z",
                            },
                        ]}
                    ></EventCalendarWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default MyTasks;
