import { useNavigate } from "react-router-dom";
import { PMMenu } from "../lib";

export interface MenuWidgetProps {
    data?: Array<Object>;
    datamapper?: Object;
    onClick?: Function;
    children?: React.ReactDOM;
}
export function MenuWidget(props: MenuWidgetProps) {
    const history = useNavigate();

    const MenuHomeNavigate = (data) => {
        history("" + "" + data["url"]);
    };

    const MenuHomeCLick = (data) => {
        MenuHomeNavigate(data);
    };

    const createComponent = (data, index) => {
        return (
            <PMMenu
                open={true}
                logo="https://dhofarinsurance.com/sites/default/files/logo_0_1.png"
                onClick={MenuHomeCLick}
                menuItems={[
                    { type: "Subheader", text: "Home" },
                    {
                        type: "Link",
                        text: "Dashboard",
                        url: "/my/dashboard",
                        icon: { icon: "home", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "My Tasks",
                        url: "/my/tasks",
                        icon: { icon: "people", text_color: "info", size: "large" },
                    },
                    { type: "Subheader", text: "Leads" },
                    {
                        type: "Link",
                        text: "Summary",
                        url: "/lead/dashboard",
                        icon: { icon: "home", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "My Leads",
                        url: "/lead/list",
                        icon: { icon: "people", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "Add a Lead",
                        url: "/lead/create",
                        icon: { icon: "people", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    { type: "Subheader", text: "Partners" },
                    {
                        type: "Link",
                        text: "Summary",
                        url: "/partner/dashboard",
                        icon: { icon: "home", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "My Partners",
                        url: "/partner/list",
                        icon: { icon: "people", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "Add a Partner",
                        url: "/partner/create",
                        icon: { icon: "home", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    { type: "Subheader", text: "Campaigns" },
                    {
                        type: "Link",
                        text: "Dashboard",
                        url: "/campaign/dashboard",
                        icon: { icon: "home", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "My Campaigns",
                        url: "/campaign/list",
                        icon: { icon: "people", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "Add a Campaign",
                        url: "/campaign/create",
                        icon: { icon: "people", text_color: "info", size: "large" },
                    },
                ]}
            >
                {props?.children}
            </PMMenu>
        );
    };

    return (
        <>
            {Array.isArray(props.data)
                ? props.data?.map((ele, index) => {
                      return createComponent(ele, index);
                  })
                : props.data
                ? createComponent(props.data, "1")
                : createComponent([], "1")}
        </>
    );
}
export default MenuWidget;
