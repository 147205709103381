import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const employeeDetailSlice = createApi({
    reducerPath: "employeeDetail",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        employeeDetail: builder.query({
            query: (data) => ({ url: "/org/api/v1/employee/detail", params: data, method: "GET" }),
        }),
    }),
});

export const { useEmployeeDetailQuery } = employeeDetailSlice;
