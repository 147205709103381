// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useEmployeeListQuery } from "../store/employeeListSlice";

import { useEmployeeTargetCreateMutation } from "../store/employeeTargetCreateSlice";
import { default as employeeListFormatter } from "../transformations/employeeListFormatter";
function EmployeeTargetCreate() {
    const [employeeListSkip, setemployeeListSkip] = useState(true);
    const [employeeListParams, setemployeeListParams] = useState({});
    const employeeListQuery = useEmployeeListQuery(employeeListParams, { skip: employeeListSkip });
    const employeeTargetCreateMutation = useEmployeeTargetCreateMutation();
    const dispatch = useDispatch();
    const employeeListData = useSelector((states) => states?.appStore?.employeeListData);

    const employeeTargetCreate = async (data) => {
        await employeeTargetCreateMutation[0](data).unwrap();
    };
    const employeeTargetCreateApiSuscess = (data) => {};
    useEffect(() => {
        if (employeeTargetCreateMutation[1].isSuccess) {
            employeeTargetCreateApiSuscess(employeeTargetCreateMutation[1]);
        }
    }, [employeeTargetCreateMutation]);

    const createEmployeeTarget = (data) => {
        employeeTargetCreate(data);
    };

    const saveEmployeeListData = (data) => {
        var formatedData = {
            ...employeeListFormatter(data.data || data, "employeeListData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadEmployeeDataSuccess = (data) => {
        saveEmployeeListData(data);
    };
    useEffect(() => {
        if (employeeListQuery.isSuccess) {
            loadEmployeeDataSuccess(employeeListQuery);
        }
    }, [employeeListQuery]);
    const employeeList = () => {
        setemployeeListSkip(false);
    };

    useEffect(() => {
        employeeList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Create New Lead"
                        defaultValues={{ is_currency: false }}
                        fieldsets={[
                            {
                                direction: "column",
                                fields: [
                                    {
                                        label: "Employee",
                                        name: "employee_id",
                                        type: "select",
                                        required: true,
                                        options: employeeListData,
                                    },
                                    { label: "Year", name: "last_name", type: "text", required: true },
                                    { label: "Month", name: "phone", type: "text", required: true },
                                    { label: "Target Name", name: "name", type: "text", required: true },
                                    { label: "is Currency", name: "is_currency", type: "hidden", required: true },
                                    { label: "Value", name: "target", type: "text", required: true },
                                ],
                            },
                        ]}
                        Onsubmit={createEmployeeTarget}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default EmployeeTargetCreate;
