// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, PMCard, PMIcon, PMText } from "../lib";
import { MenuWidget } from "./MenuWidget";

function Home() {
    const agentCustomerSummaryData = useSelector((states) => states?.appStore?.agentCustomerSummaryData);
    const followupSummaryData = useSelector((states) => states?.appStore?.followupSummaryData);
    const history = useNavigate();

    const afterLogin = (data) => {
        history("/partnerList");
    };

    const LeadTableClick = (data) => {
        afterLogin(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid>
                    <PMGrid marginTop={5}>
                        <PMCard raised={true} elevation={2} grid={12}>
                            <PMGrid xs={12} spacing={5} direction="row" container={true} marginBottom={2}>
                                <PMGrid xs={10} item={true}>
                                    <PMGrid children="Overview"></PMGrid>
                                </PMGrid>
                            </PMGrid>

                            <PMGrid container={true} spacing={2} direction="row" marginTop={2}>
                                <PMGrid xs={4} item={true} justifyContent="center">
                                    <PMCard onClick={LeadTableClick} bgColor="primary">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon
                                                icon="MdSupervisedUserCircle"
                                                size="h1"
                                                text_color="secondary"
                                            ></PMIcon>

                                            <PMText
                                                variant="caption"
                                                children="Total Assigned Partners"
                                                color="white"
                                            ></PMText>

                                            <PMText
                                                variant="h6"
                                                color="white"
                                                children={agentCustomerSummaryData?.total_assigned || []}
                                            ></PMText>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid xs={4} item={true} justifyContent="center">
                                    <PMCard bgColor="greenish">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon
                                                icon="MdSupervisedUserCircle"
                                                size="h1"
                                                text_color="secondary"
                                            ></PMIcon>

                                            <PMText variant="caption" children="Active Partners" color="white"></PMText>

                                            <PMGrid
                                                container={true}
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <PMText variant="h6" color="white" children={2}></PMText>

                                                <PMText variant="h6" color="white" children="/"></PMText>

                                                <PMText variant="h6" color="white" children={4}></PMText>
                                            </PMGrid>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>
                            </PMGrid>
                        </PMCard>
                    </PMGrid>

                    <PMGrid xs={12} marginTop={5}>
                        <PMCard raised={true} elevation={2} grid={12}>
                            <PMGrid xs={12} spacing={5} direction="row" container={true} marginBottom={2}>
                                <PMGrid xs={10} item={true}>
                                    <PMGrid children="Milestone Summary"></PMGrid>
                                </PMGrid>
                            </PMGrid>

                            <PMGrid
                                xs={12}
                                justifyContent="center"
                                container={true}
                                spacing={2}
                                direction="row"
                                marginTop={2}
                            >
                                <PMGrid flex={2} item={true} justifyContent="center" direction="row">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon icon="MdLocationPin" size="h1" text_color="primary"></PMIcon>

                                            <PMText variant="caption" children="Acquired"></PMText>

                                            <PMText variant="h6" color="primary" children={2}></PMText>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid
                                    item={true}
                                    flex={1}
                                    container={true}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <PMIcon icon="MdNavigateNext" size="h1" text_color="primary"></PMIcon>
                                </PMGrid>

                                <PMGrid flex={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon icon="MdLocationPin" size="h1" text_color="primary"></PMIcon>

                                            <PMText variant="caption" children="First Connect"></PMText>

                                            <PMText variant="h6" color="primary" children={1}></PMText>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid
                                    item={true}
                                    flex={1}
                                    container={true}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <PMIcon icon="MdNavigateNext" size="h1" text_color="primary"></PMIcon>
                                </PMGrid>

                                <PMGrid flex={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon icon="MdLocationPin" size="h1" text_color="primary"></PMIcon>

                                            <PMText variant="caption" children="Training Done"></PMText>

                                            <PMText variant="h6" color="primary" children={1}></PMText>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid
                                    item={true}
                                    flex={1}
                                    container={true}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <PMIcon icon="MdNavigateNext" size="h1" text_color="primary"></PMIcon>
                                </PMGrid>

                                <PMGrid flex={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon icon="MdLocationPin" size="h1" text_color="primary"></PMIcon>

                                            <PMText variant="caption" children="Exam Passed"></PMText>

                                            <PMText variant="h6" color="primary" children={0}></PMText>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid
                                    item={true}
                                    flex={1}
                                    container={true}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <PMIcon icon="MdNavigateNext" size="h1" text_color="primary"></PMIcon>
                                </PMGrid>

                                <PMGrid flex={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMIcon icon="MdLocationPin" size="h1" text_color="primary"></PMIcon>

                                            <PMText variant="caption" children="Activated"></PMText>

                                            <PMText variant="h6" color="primary" children={2}></PMText>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>
                            </PMGrid>
                        </PMCard>
                    </PMGrid>

                    <PMGrid xs={12} marginTop={5}>
                        <PMCard raised={true} elevation={2} grid={12}>
                            <PMGrid xs={12} spacing={5} direction="row" container={true} marginBottom={2}>
                                <PMGrid xs={8} item={true}>
                                    <PMGrid children="Planned Activity for Today"></PMGrid>
                                </PMGrid>
                            </PMGrid>

                            <PMGrid
                                xs={12}
                                justifyContent="flex-start"
                                container={true}
                                spacing={2}
                                direction="row"
                                marginTop={2}
                            >
                                <PMGrid xs={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMGrid container={true} direction="row" alignItems="center">
                                                <PMIcon icon="MdCall" size="h1" text_color="primary"></PMIcon>

                                                <PMGrid direction="column">
                                                    <PMText variant="caption" children="Calls"></PMText>

                                                    <PMText
                                                        variant="h6"
                                                        color="primary"
                                                        children={followupSummaryData?.Call || []}
                                                    ></PMText>
                                                </PMGrid>
                                            </PMGrid>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid xs={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMGrid container={true} direction="row" alignItems="center">
                                                <PMIcon icon="MdSms" size="h1" text_color="primary"></PMIcon>

                                                <PMGrid direction="column">
                                                    <PMText variant="caption" children="SMS"></PMText>

                                                    <PMText
                                                        variant="h6"
                                                        color="primary"
                                                        children={followupSummaryData?.SMS || []}
                                                    ></PMText>
                                                </PMGrid>
                                            </PMGrid>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid xs={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMGrid container={true} direction="row" alignItems="center">
                                                <PMIcon icon="MdEmail" size="h1" text_color="primary"></PMIcon>

                                                <PMGrid direction="column">
                                                    <PMText variant="caption" children="Email"></PMText>

                                                    <PMText
                                                        variant="h6"
                                                        color="primary"
                                                        children={followupSummaryData?.Email || []}
                                                    ></PMText>
                                                </PMGrid>
                                            </PMGrid>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>

                                <PMGrid xs={2} item={true} justifyContent="center">
                                    <PMCard bgColor="info-50">
                                        <PMGrid container={true} direction="column" alignItems="center">
                                            <PMGrid container={true} direction="row" alignItems="center">
                                                <PMIcon icon="MdChatBubble" size="h1" text_color="primary"></PMIcon>

                                                <PMGrid direction="column">
                                                    <PMText variant="caption" children="WhatsApp"></PMText>

                                                    <PMText
                                                        variant="h6"
                                                        color="primary"
                                                        children={followupSummaryData?.WhatsApp || []}
                                                    ></PMText>
                                                </PMGrid>
                                            </PMGrid>
                                        </PMGrid>
                                    </PMCard>
                                </PMGrid>
                            </PMGrid>
                        </PMCard>
                    </PMGrid>

                    <PMGrid grid={12} marginBottom={5}></PMGrid>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Home;
