// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useCampaignCreateMutation } from "../store/campaignCreateSlice";

function CampaignCreate() {
    const campaignCreateMutation = useCampaignCreateMutation();

    const campaignCreate = async (data) => {
        await campaignCreateMutation[0](data).unwrap();
    };
    const campaignCreateApiSucccess = (data) => {};
    useEffect(() => {
        if (campaignCreateMutation[1].isSuccess) {
            campaignCreateApiSucccess(campaignCreateMutation[1]);
        }
    }, [campaignCreateMutation]);

    const createlead = (data) => {
        campaignCreate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true}>
                    <FormWidget
                        direction="column"
                        header="Create a new Campaign"
                        fieldsets={[
                            {
                                direction: "column",
                                fields: [
                                    { label: "Name", name: "name", type: "text", required: true },
                                    { label: "Start Date", name: "start_date", type: "date", required: true },
                                    { label: "End Date", name: "end_date", type: "date", required: true },
                                    {
                                        label: "Budgeted Cost",
                                        name: "budgeted_cost",
                                        type: "number",
                                        required: true,
                                        prefix: "Rs.",
                                    },
                                    {
                                        label: "Expected Acquisitions",
                                        name: "expected_acquisitions",
                                        type: "number",
                                        required: false,
                                    },
                                    { label: "Source", name: "medium", type: "text", required: true },
                                    { label: "Medium", name: "source", type: "text", required: true },
                                    { label: "Term", name: "term", type: "text", required: false },
                                    { label: "Content", name: "content", type: "text", required: false },
                                ],
                            },
                        ]}
                        Onsubmit={createlead}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default CampaignCreate;
