const employeeTargetFormatter = (data, dataKey) => {
    const dataSet = {};
    if (data.total && data.total > 0) {
        dataSet[dataKey] = JSON.parse(JSON.stringify(data.items[0]));
    } else {
        dataSet[dataKey] = {};
    }
    return dataSet;
};

export default employeeTargetFormatter;
