import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const partnerCreateSlice = createApi({
    reducerPath: "partnerCreate",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        partnerCreate: builder.mutation({
            query: (data) => ({ url: "/acquisition/api/v1/campaign/create", method: "POST", body: data }),
        }),
    }),
});

export const { usePartnerCreateMutation } = partnerCreateSlice;
