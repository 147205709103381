const leadListFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data.items));
    dataSet[dataKey].forEach((element) => {
        element["full_name"] = element["first_name"] + " " + element["last_name"];
        element["address"] = element["address_line_1"] + "," + element["address_line_2"];
        element["icon"] = "people";
    });
    dataSet["leadCount"] = dataSet[dataKey].length
    return dataSet;
};

export default leadListFormatter;
