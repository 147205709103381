const employeeListFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data.items));
    dataSet[dataKey].forEach((element) => {
        element["label"] = element["id"];
    });

    return dataSet;
};

export default employeeListFormatter;
