// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, ChartWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function PartnerDashboard() {
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true}>
                    <ChartWidget
                        xs={6}
                        header="Campaign Performance"
                        type="bar"
                        datasets={[
                            [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                            [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                        ]}
                    ></ChartWidget>

                    <ChartWidget
                        xs={6}
                        header="Campaign Performance"
                        type="pie"
                        datasets={[[12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3]]}
                    ></ChartWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default PartnerDashboard;
