// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { PMGrid, BaseWidget, PMTable } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useLeadListQuery } from "../store/leadListSlice";
import { default as leadListFormatter } from "../transformations/leadListFormatter";
function PartnerList() {
    const leadData = useSelector((states) => states?.appStore?.leadData);
    const [leadListSkip, setleadListSkip] = useState(true);
    const [leadListParams, setleadListParams] = useState({});
    const leadListQuery = useLeadListQuery(leadListParams, { skip: leadListSkip });
    const history = useNavigate();
    const dispatch = useDispatch();

    const afterLogin = (data) => {
        history("/partner" + "/" + data["id"]);
    };

    const LeadTableClick = (data) => {
        afterLogin(data);
    };

    const saveLeadData = (data) => {
        var formatedData = {
            ...leadListFormatter(data.data || data, "leadData"),
        };
        dispatch(setStore(formatedData));
    };

    const loadLeadDataSuccess = (data) => {
        saveLeadData(data);
    };
    useEffect(() => {
        if (leadListQuery.isSuccess) {
            loadLeadDataSuccess(leadListQuery);
        }
    }, [leadListQuery]);
    const leadList = () => {
        setleadListSkip(false);
    };

    useEffect(() => {
        leadList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid>
                    <BaseWidget header="All Leads">
                        <PMTable
                            rows={leadData || []}
                            columns={[
                                { field: "full_name", headerName: "Partner name", minWidth: 200 },
                                { field: "address", headerName: "Address", minWidth: 200 },
                                { field: "priority", headerName: "Priority", minWidth: 200 },
                                { field: "current_milestone", headerName: "Current Milestone", minWidth: 200 },
                                { field: "phone", headerName: "Contact Number", minWidth: 200 },
                            ]}
                            Onclick={LeadTableClick}
                        ></PMTable>
                    </BaseWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default PartnerList;
