// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import {
    PMGrid,
    DetailWidget,
    MetricWidget,
    PMCard,
    PMText,
    Forms,
    PMInput,
    PMButton,
    PMDropdown,
    PMStepper,
    PMCheckbox,
} from "../lib";
import { MenuWidget } from "./MenuWidget";

function PartnerDetail() {
    const thisLead = useSelector((states) => states?.appStore?.thisLead);
    const thisCampaign = useSelector((states) => states?.appStore?.thisCampaign);
    const stepperLength = useSelector((states) => states?.appStore?.stepperLength);
    const { id } = useParams();

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <PMGrid container={true} xs={12}>
                        <DetailWidget
                            direction="row"
                            header="Basic Information"
                            xs={6}
                            data={thisLead || []}
                            fields={[
                                { label: "First Name", key: "first_name" },
                                { label: "Last Name", key: "last_name" },
                                { label: "Phone", key: "phone" },
                                { label: "Email", key: "email" },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            xs={6}
                            header="Professional Info"
                            data={thisLead || []}
                            fields={[
                                { label: "First Name", key: "first_name" },
                                { label: "Last Name", key: "last_name" },
                                { label: "Phone", key: "phone" },
                                { label: "Email", key: "email" },
                            ]}
                        ></DetailWidget>
                    </PMGrid>

                    <PMGrid container={true} xs={12} direction="row">
                        <MetricWidget header="Total Policies" text="24"></MetricWidget>

                        <MetricWidget header="Total Premium" text="24"></MetricWidget>

                        <MetricWidget header="Target" text="23,000"></MetricWidget>

                        <MetricWidget header="Sample Metric" text="24"></MetricWidget>
                    </PMGrid>

                    <PMGrid xs={12} marginTop={2} spacing={2} container={true} direction="row">
                        <PMGrid marginTop={2} spacing={2} flex={3} item={true}>
                            <PMGrid spacing={2}>
                                <PMCard>
                                    <PMText children="Notes" variant="caption"></PMText>

                                    <PMGrid item={true} marginTop={2}>
                                        <Forms>
                                            <PMInput
                                                multiline={true}
                                                name="note"
                                                variant="outlined"
                                                label="Add Note"
                                            ></PMInput>

                                            <PMInput name="lead_id" hideContent={true} defaultValue={id}></PMInput>

                                            <PMGrid justifyContent="flex-end" marginTop={2} container={true}>
                                                <PMButton variant="contained" type="submit" label="Submit"></PMButton>
                                            </PMGrid>
                                        </Forms>
                                    </PMGrid>
                                </PMCard>
                            </PMGrid>
                        </PMGrid>

                        <PMGrid marginTop={2} spacing={2} flex={1} item={true}>
                            <PMGrid spacing={2}>
                                <PMCard>
                                    <PMText children="Status" variant="caption"></PMText>

                                    <PMGrid item={true} marginTop={2}>
                                        <Forms>
                                            <PMDropdown
                                                name="milestone_id"
                                                label="Select Status"
                                                margin="normal"
                                                options={thisCampaign || []}
                                            ></PMDropdown>

                                            <PMInput name="lead_id" hideContent={true} defaultValue={id}></PMInput>

                                            <PMGrid justifyContent="flex-end" marginTop={2} container={true}>
                                                <PMButton variant="contained" type="submit" label="Submit"></PMButton>
                                            </PMGrid>
                                        </Forms>
                                    </PMGrid>
                                </PMCard>
                            </PMGrid>

                            <PMCard>
                                <PMStepper
                                    orientation="vertical"
                                    activeStep={stepperLength || []}
                                    steps={thisCampaign || []}
                                ></PMStepper>
                            </PMCard>
                        </PMGrid>

                        <PMGrid marginTop={2} spacing={2} flex={3} item={true}>
                            <PMCard>
                                <PMGrid container={true} spacing={2} direction="column">
                                    <PMGrid item={true}>
                                        <PMText children="Messaging" variant="caption"></PMText>
                                    </PMGrid>

                                    <PMGrid item={true}>
                                        <PMDropdown
                                            variant="outlined"
                                            label="Mode of Interaction"
                                            options={[{ value: "WhatsApp" }, { value: "Email" }, { value: "SMS" }]}
                                        ></PMDropdown>
                                    </PMGrid>

                                    <PMGrid item={true}>
                                        <PMInput multiline={true} rows={2} label="Type your message here"></PMInput>
                                    </PMGrid>

                                    <PMGrid item={true}>
                                        <PMCheckbox label="Artifact 1"></PMCheckbox>

                                        <PMCheckbox label="Artifact 2"></PMCheckbox>

                                        <PMCheckbox label="Artifact 3"></PMCheckbox>

                                        <PMCheckbox label="Artifact 4"></PMCheckbox>

                                        <PMCheckbox label="Artifact 5"></PMCheckbox>
                                    </PMGrid>

                                    <PMGrid justifyContent="flex-end" xs={12} item={true} container={true}>
                                        <PMButton variant="contained" label="Send message"></PMButton>
                                    </PMGrid>
                                </PMGrid>
                            </PMCard>
                        </PMGrid>
                    </PMGrid>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default PartnerDetail;
