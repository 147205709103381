import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const stateDetailSlice = createApi({
    reducerPath: "stateDetail",
    baseQuery: fetchBaseQuery({ baseUrl: "http://connect.demo.permute.in" }),
    endpoints: (builder) => ({
        stateDetail: builder.query({
            query: (data) => ({ url: "/org/api/v1/state/detail", params: data, method: "GET" }),
        }),
    }),
});

export const { useStateDetailQuery } = stateDetailSlice;
